import {TemplateRef} from '@angular/core';

export class UrlParameter {
  constructor(
    public key: string,
    public value: string
  ) {
  }
}

export class UrlParameters {
  public urlParameters: UrlParameter[];

  constructor() {
    this.urlParameters = [];
  }

  public get length(): number {
    return this.urlParameters.length;
  }

  public add(urlParameter: UrlParameter) {
    if (urlParameter.value !== undefined && urlParameter.value !== '' && urlParameter.value !== null) {
      let exist = -1;
      this.urlParameters.filter((value, index)=> {
        if (value.key === urlParameter.key) {
          exist = index;
        }
      });
      // if exists, it updates the value
      if (exist < 0) {
        this.urlParameters.push(urlParameter);
      } else {
        this.urlParameters[exist] = urlParameter;
      }
    }
  }

  public addParameter(key: string, value: string) {
    this.add(new UrlParameter(key, value));
  }

  public toString() {
    let paramstring = '';
    let i = 0;

    this.urlParameters.forEach( (param)=> {
      paramstring += (i === 0 ? '?' : '&') + param.key + '=' + encodeURIComponent(String(param.value));
      i++;
    });

    return paramstring;
  }
  public toObject(encode= true) {
    let obj = {};

    this.urlParameters.forEach((param) => {
      if (Object.keys(obj).length === 0) {
        obj = { [param.key]: encode? encodeURIComponent(param.value): param.value };
      } else {
        obj[param.key] = encode ? encodeURIComponent(param.value) : param.value;
      }
    });

    return obj;
  }

  public concat(params: UrlParameters) {
    if (params) {
      params.urlParameters.forEach(value => {
        this.add(value);
      });
    }
  }

  public remove(key: string) {
    let exist = -1;
    this.urlParameters.filter((value, index)=> {
      if (value.key === key) {
        exist = index;
      }
    });
    // if exists, it removes the value
    if (exist >= 0) {
      this.urlParameters.splice(exist, 1);
    }
  }
}

export class TableResponse<T> {
  constructor(
    public content: T[],
    public empty: boolean,
    public first: boolean,
    public last: boolean,
    public number: number,
    public numberOfElements: number,
    public pageable: any,
    public size: number,
    public sort: any,
    public totalElements: number,
    public totalPages: number,
    public params: UrlParameter[] // for passing params to the result for further processing (fa matching recipes to periods)

  ) {
  }
}

export class TableParameters {
  constructor(
    public page: number,
    public size: number,
    public sortproperty: string,
    public sortdirection: string,
  ) {
  }

  public getUrlParameters(): UrlParameters {
    const params = new UrlParameters();
    params.addParameter('page', String(this.page));
    params.addParameter('size', String(this.size));
    params.addParameter('sort', this.sortproperty + ',' + this.sortdirection);

    return params;
  }

}

export class Brand {
  constructor(
    public id: number,
    public name: string,
    public frontenddomain: string,
    public emailfrom: string,
    public emailfromname: string,
    public enabled: boolean
  ) {
  }
}

export class NotifydialogData {
  constructor(
    public imageurl: string,
    public descriptionTemplateRef: TemplateRef<any>
  ) {
  }
}

export enum Dialogtype {
  YesNo = 'YesNo',
  YesNoCancel = 'YesNoCancel',
  OkOnly = "OkOnly"
}

export class BottomdrawerdialogData {
  constructor(
    public templateRef: TemplateRef<any>
  ) {
  }
}

export class Visits{

  minutesSinceFirstVisit: number;
  minutesSinceLastVisit: number;
  last_visit: Date;

  constructor(
    public first_visit: Date,
    public total_pageview_visits: number,
    public total_day_visits: number,
    public customerid: number,
  ){

    this.last_visit = new Date();
    this.total_pageview_visits++;

    if(!(this.first_visit.getDate() === this.last_visit.getDate() && this.first_visit.getMonth() === this.last_visit.getMonth() && this.first_visit.getFullYear() === this.last_visit.getFullYear())){
      this.total_day_visits++;
    }

    this.minutesSinceFirstVisit = Math.round((new Date().getTime() - this.first_visit.getTime())/60000);
    this.minutesSinceLastVisit = Math.round((new Date().getTime() - this.last_visit.getTime())/60000);
  }


}

export enum Currency {
  EUR = 'EUR'
}

export enum Language {
  NL = 'NL',
  EN = 'EN',
  FR = 'FR'
}

// order of types is important for hierarchy
// URL should overridde the browser ! but not the custommer !
export enum Sourcetype {
  BROWSER = "BROWSER",
  URL = "URL",
  CUSTOMER = "CUSTOMER"
}
export class Preferredlanguage {
  constructor(
    public languagetype: Sourcetype,
    public language: Language
  ) {

  }
}
export class Activepricecountry {
  constructor(
    public pricecountrytype: Sourcetype,
    public pricecountry: Countrycode
  ) {

  }
}

export class CheckdialogData {
  constructor(
    public title: string,
    public description: string,
    public dialogtype: Dialogtype,
    public color: string,
    public custombuttons: Dialogbutton[] = null
  ) { }
}
export class Dialogbutton {
  constructor(
    public value: string,
    public displayvalue: string
  ) { }
}

export enum PlaceholderPreset{
  RECIPE = "RECIPE",
  RECIPE_CONDENSED = "RECIPE_CONDENSED"}

export enum Customercountrycode {
  NL = 'NL',
  BE = 'BE'
  // DE = "DE",
  // FR = "FR",

}

export enum Countrycode {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AN = 'AN',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW'

}
